import React from 'react'
import GoogleMapReact from 'google-map-react';

// import mapStyle from './mapStyle'

export default function Map () {

	const officePosition = {
		center: {
			lat: 51.50023,
			lng: -0.08496
		},
		zoom: 12.3
	}

	const renderMarkers = ( map, maps ) => {
		let marker = new maps.Marker( {
			position: { lat: officePosition.center.lat, lng: officePosition.center.lng },
			map,
			title: 'We are the Trend office'
		} );
		return marker;
	};

	return (
		// Important! Always set the container height explicitly
		<div style={{ height: '100%', width: '100%' }}>
			<GoogleMapReact
				bootstrapURLKeys={{ key: process.env.GOOGLE_MAP_KEY }}
				// options={{ styles: mapStyle }}
				defaultCenter={officePosition.center}
				defaultZoom={officePosition.zoom}
				yesIWantToUseGoogleMapApiInternals
				onGoogleApiLoaded={({ map, maps }) => renderMarkers( map, maps ) } />
		</div>
	)
}