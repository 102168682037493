import React, { useState, useRef } from 'react'
import axios from 'axios'

import { FaSpinner } from 'react-icons/fa'
import Anchor from '../Anchor/Anchor'
import Map from '../Map/Map'

import TopVideoCompanyLogo from '../../images/top-video-production-logo.png'

const EMAIL_KEY = process.env.EMAIL_KEY || 'kieron123'

export default function Contact () {

	const form = useRef();
	const [ name, setName ] = useState('')
	const [ email, setEmail ] = useState('')
	const [ company, setCompany ] = useState( '' )
	const [ messageDetails, setMessageDetails ] = useState('')
	const [ formSuccess, setFormSuccess ] = useState(false)
	const [ error, setError ] = useState('')
	const [ loading, setLoading ] = useState()

	const handleSubmit = async ( event ) => {
		event.preventDefault()
		form.current.reportValidity();

		setLoading( true )

		const formData = {
			name,
			email,
			company,
			messageDetails,
			EMAIL_KEY
		}

		try {
			const result = await axios.post('/api/email', formData);

			if ( result.status === 200 ) {
				setFormSuccess( true )
				setLoading( false )
			}
		} catch ( error ) {
			console.log( error )
			setError('Error')
			setLoading( false )
		}
	}

	return (
		<section className="contact-us">
			<Anchor anchorId="contact" />
			<div className="contact-us__wrapper">
				<h2 className="contact-us__heading">Get in touch</h2>
				<div className="contact-us__form-container">
					{ !formSuccess ? (
						<form className="form" onSubmit={ handleSubmit } ref={ form }>
							<div className={`form__input-container ${ error ? 'error' : '' }`}>
								<label className="form__label" htmlFor="name">Name</label>
								<input
									onChange={ e => { setName(e.target.value) }}
									value={ name }
									className="form__input" id="name" type="text" placeholder="" required />
							</div>

							<div className={`form__input-container ${ error ? 'error' : '' }`}>
								<label className="form__label" htmlFor="email">Contact Email</label>
								<input
									onChange={ e => { setEmail(e.target.value) }}
									value={ email }
									className="form__input" id="email" type="email" placeholder="" required />
							</div>

							<div className={`form__input-container ${ error ? 'error' : '' }`}>
								<label className="form__label" htmlFor="email">Company (Optional)</label>
								<input
									onChange={ e => { setCompany(e.target.value) }}
									value={ company }
									className="form__input" id="company" type="text" placeholder="" />
							</div>

							<div className={`form__input-container ${ error ? 'error' : '' }`}>
								<label className="form__label" htmlFor="message">Tell us about your idea</label>
								<textarea
									onChange={ e => { setMessageDetails(e.target.value) }}
									value={ messageDetails}
									className="form__input" id="message" type="text" placeholder="" rows="6" required />
							</div>

							<input type="hidden" id="" name="" value=""></input>

							{ error.length >= 1 ? (
								<div className="form__error-container">
									<span className="form__error-message">Sorry, we have been unable to send your message.</span>
									<span className="form__error-message">Please try again later.</span>
								</div>
							) : (
								<button className="form__submit" type="submit">Send</button>
							) }

							{ loading &&
								<div className="form__loading-container">
									<div className="form__loading-background"></div>
									<FaSpinner className="loader" />
									<h3 className="form__loading-text">Sending</h3>
								</div>
							}
						</form>
					) : (
						<p className="contact-us__success">
							Thank you for getting in touch. We will respond to your message as soon as possible!
						</p>
					) }
				</div>
				<div className="contact-us__map-container">
					<Map />
				</div>
				<div className="contact-us__meta-data">
					<div className="contact-us__address-container">
						<div className="contact-us__address-title">Head Office:</div>
						<span className="contact-us__address">WE ARE THE TREND LTD</span>
						<span className="contact-us__address">308, The Leather Market</span>
						<span className="contact-us__address">11-13 Weston Street</span>
						<span className="contact-us__address">London</span>
						<span className="contact-us__address">SE1 3ER</span>
						<span className="contact-us__address">United Kingdom</span>
					</div>
					<div className="contact-us__vat-container">
						<span className="contact-us__vat">WE ARE THE TREND LTD is a VAT registered company</span>
						<span className="contact-us__vat">VAT reg: 378945921</span>
					</div>

					<a
						href="https://www.designrush.com/agency/profile/we-are-the-trend-ltd"
						target="_blank"
						rel="noreferrer"
						className="contact-us__rewards-link">
						<img src={ TopVideoCompanyLogo } className="contact-us__rewards-logo" alt="" />
					</a>
				</div>
			</div>
		</section>
	)
}