import React from 'react'
import { graphql } from 'gatsby'

import Seo from '../components/SEO/Seo'
import Contact from '../components/Contact/Contact'

import DynamicZone from '../components/DynamicZone/DynamicZone'

export default function Home({ data: { strapi: { homepage } }, location }) {

	return (
		<>
			<Seo title="Video & Photography Specialists" pathname={ location.pathname } />

			{ homepage && homepage.content &&
				<DynamicZone components={ homepage.content } />
			}

			<Contact />
		</>
	)
}

export const query = graphql`
	query homePage {
		strapi {
			homepage {
				seoDescription
				content {
					__typename
					... on StrapiQuery_ComponentHomeHomeHero {
						id
						videoNumber
						image {
							url
							imageFile {
								childImageSharp {
									gatsbyImageData
								}
							}
						}
					}
					... on StrapiQuery_ComponentHomeServiceList {
						id
						services {
							id
							serviceTitle
							serviceUrl
						}
					}
					... on StrapiQuery_ComponentHomeAbout {
						id
						aboutTextBlockOne
						aboutTextBlockTwo
						aboutImages {
							url
							imageFile {
								childImageSharp {
									gatsbyImageData
								}
							}
						}
						servicesEquipmentTitle
						servicesList {
							serviceListItem
						}
						equipmentList {
							equipmentListItem
						}
					}
					... on StrapiQuery_ComponentHomeClientsList {
						id
						clientsMain {
							id
							clientMainTitle
							clientMainLogo {
								url
								imageFile {
									childImageSharp {
										gatsbyImageData
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentHomeTestimonials {
						id
						testimonials {
							id
							nameAndRole
							company
							testimonial
							testimonialLogo {
								url
								imageFile {
									childImageSharp {
										gatsbyImageData
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentGlobalImageBanner {
						id
						text
						image {
							url
							imageFile {
								childImageSharp {
									gatsbyImageData
								}
							}
						}
					}
				}
			}
		}
	}
`